












































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import { Notificacao, Pageable } from '@/models'
import { UpdateNotificacaoUseCase } from '@/usecases'
import { formatarDataHora } from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import InfiniteLoading from 'vue-infinite-loading'
import axios, { CancelTokenSource } from 'axios'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		InfiniteLoading,
	},
})
export default class Home extends Vue {
	items = AppStore.itensDeMenuPermitidos
	show: string[] = []

	cancelToken: CancelTokenSource | null = null
	posts: Notificacao[] = []
	notificacaoUseCase = new UpdateNotificacaoUseCase()
	pageable: Pageable = {
		page: 0,
		size: 10,
		sort: 'dataEHora,desc',
	}

	formatarDataHora = formatarDataHora

	mounted() {
		const tituloHome = document.getElementById('tituloHome')

		const tamanhoFont = this.obterValorPorPercentual(this.$vuetify.breakpoint.width, 6)
		
		if (tituloHome) tituloHome.style.cssText = `position: absolute; font-family: system-ui; font-size: ${tamanhoFont}px;`
	}

	created() {
		if (UserLoginStore.perfil?.telaInicial) {
			this.$router.push({ name: UserLoginStore.perfil?.telaInicial })
		}
	}

	@Watch('$vuetify.breakpoint.width')
	atualizarTitulo() {
		const tituloHome = document.getElementById('tituloHome')

		const tamanhoFont = this.obterValorPorPercentual(this.$vuetify.breakpoint.width, 6)
		
		if (tituloHome) tituloHome.style.cssText = `position: absolute; font-family: system-ui; font-size: ${tamanhoFont}px;`
	}

	get heightDaNotificacao() {
		return this.height()
	}

	get widthDaNotificacao() {
		return this.width()
	}

	get heightDaNome() {
		return this.height() -50
	}
	
	get widthDaNome() {
		return this.width()
	}

	async notificao($state: any) {
		try {
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			await this.notificacaoUseCase
				.findNotificacoes({
					page: this.pageable.page ? this.pageable.page : 0,
					size: this.pageable.size,
					sort: this.pageable.sort,
					axiosConfig,
				})
				.then(response => {
					if (response.content.length) {
						this.pageable.page = !this.pageable.page
							? 1
							: (this.pageable.page += 1)
						this.posts.push(...response.content)
						$state.loaded()
					} else {
						$state.complete()
					}
				})
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	get usuario() {
		return UserLoginStore.usuario
	}
	
	height() {
		switch (this.$vuetify.breakpoint.name) {
			case 'xs': return 150
			case 'sm': return 200
			case 'md': return 200
			case 'lg': return 200
			case 'xl': return 300
		}
	}

	width() {
		switch (this.$vuetify.breakpoint.name) {
			case 'xs': return 250
			case 'sm': return 300
			case 'md': return 300
			case 'lg': return 380
			case 'xl': return 500
		}
	}

	obterValorPorPercentual(valor: number, percentual: number) {
		return ~~((valor*percentual)/100)
	}
}
